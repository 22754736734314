body {
  font-family: 'Poppins', sans-serif;
}

.scrollElement.bodyElement {
  height: fit-content;
  padding-bottom: 50px;
  padding-top: 75px;
}

.card {
  border-radius: calc(.25rem - 1px);;
  min-width: 100px;
  max-width: 500px;
}

.card-deck {
  margin-left: 10px;
  margin-right: 10px;
}

.containerHeader {
  font-size: 3em;
}

.containerSubheader {
  margin-bottom: 50px
}


.scrollElement {
  height: 100vh;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  box-sizing: border-box;
}

.slick-next {
  height: 40px;
  right: -45px;
  width: 40px;
}

.slick-prev {
  height: 40px;
  left: -45px;
  width: 40px;
}

.slick-prev:before,
.slick-next:before {
  color: rgba(71, 149, 134, 0.9);
  font-size: 40px;
}

.slick-slider {
  margin: auto;  
  width:80%;
}

#header {
  background-color: rgba(230, 230, 230, 0.8);
  height: 50px;
  position: fixed;
}

#header a {
  color: rgb(33, 37, 41);
  cursor: pointer;
}

#header a:hover {
  color: black;
  cursor: pointer;
  text-decoration: underline;
}

#header a.active:not(.inactive) {
  color: rgba(49, 171, 148, 0.8);
}

#about {
  background-image: url(/src/images/3254308.svg);
  background-position: right 0;
  background-size: cover;
  max-height: 680px;
}

#about-container {
  float: right;  
  left: 50%;
  padding-top: 200px;
  position: absolute;
  transform: translateX(180px);
  width: 400px;
}

#about-container-header {
  font-size: 2em;
}

#about-container-description {
  font-size: 1em;
}

#contact {
  background-color: #363A3A;
  color: white;
  padding-bottom: 75px;
}

#contact-container {
  height: 100%;
}

#contact #attribution-container {
  margin-top: 40px;
}

#contact #attribution-link {
  color: #aaa;
}

#contact #attribution-link:hover {
  color: #ddd;
  text-decoration: none;
}

#contact #contact-links {
  margin-top: 40px;
}

#contact #contact-links a {
  color: white;
  font-size: 30px;
}

#contact #contact-links a:hover {
  color: #ddd;
  text-decoration: none;
}

#contact #contact-links a .linkText {
  font-size: 15px;
  vertical-align: middle;
}

#contact #email-link {
  margin-right: 30px;
}

#profile-image {
  overflow: hidden;
  visibility: hidden;
  -webkit-transform: rotate(120deg);
     -moz-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
       -o-transform: rotate(120deg);
          transform: rotate(120deg);
  width: 400px;
  height: 200px;
}

#profile-image-in1 {
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(-60deg);
     -moz-transform: rotate(-60deg);
      -ms-transform: rotate(-60deg);
       -o-transform: rotate(-60deg);
          transform: rotate(-60deg);
}

#profile-image-in2 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position-x: -5px;
  background-position-y: 18%;
  background-image: url(/src/images/profile-photo-2.jpg);
  background-size: 400px 400px;
  visibility: visible;
  -webkit-transform: rotate(-60deg);
     -moz-transform: rotate(-60deg);
      -ms-transform: rotate(-60deg);
       -o-transform: rotate(-60deg);
          transform: rotate(-60deg);
}

#projects {
  background-color: #DAE5E3;
}

#projects .card {
  cursor: pointer;
  margin-top: min(20px, 3%);
}

#projects .card img {
  transition: 0.5s ease;
  -moz-transition: .5s ease;
  -webkit-transition: .5s ease;
}

#projects .card .overlay {
  width: 100%;
  height: 100%;

  top: 0;

  background-color: rgba(71, 149, 134, 0.8);
  border-radius: calc(.25rem - 1px);
  opacity: 0;
  position: absolute;
  text-align: center;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  transition: 0.5s ease;
  -moz-transition: .5s ease;
  -webkit-transition: .5s ease;
}

#projects .card:hover .overlay {
  opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
}

#projects .card .overlay .overlayContent {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4%;
  text-align: left;
}

#projects .card .overlay .overlayContent hr {
  border: 1px solid white;
  border-radius: 5px;
}

#technical-writing {
  margin-bottom: 20px;
}

#technical-writing hr {
  background-color: #aaa;
  border: none;
  color: #aaa;
  height: 1px;
  margin-bottom: 10px;
  margin-top: 7px;
  width: 50px;
}

#technical-writing .card {
  border: 1px solid #ccc;
  border-radius: 0px;
  height: 400px;
  margin: auto;
  max-width: 290px;
  overflow: visible;
  /* padding-left: 5px; */
  /* padding-right: 5px; */
}

#technical-writing .card a {
  color: black;
}

#technical-writing .card a:hover {
  color: #777;
  text-decoration: none;
}

#technical-writing .card img {
  border-radius: 0px;
}

#technical-writing .card img:hover {
  box-shadow: 0 0 5px #333;
}

#technical-writing .card-body {
  margin-top: 5px;
  padding: 0px;
}

#technical-writing .card-text {
  font-size: 14px;
}

#technical-writing .card-title {
  margin-bottom: 0px;
  margin-top: 10px;
}

#technical-writing .slick-slide {
  padding-bottom: 5px;
  padding-top: 5px;
}

#technical-writing .writingSubheader {
  font-size: 10px;
}

#technical-writing .writingBody {
  padding-left: 8px;  
  padding-right: 8px;
}

/* for medium displays */
@media (max-width: 1262px) {
  #about-container {
    float: right;  
    left: 0%;
    position: inherit;
    transform: none;
    margin-right: 100px;
    padding-top: 200px;
    width: 400px;
  }
}

/* for small displays */
@media (max-width: 768px) {
  .slick-next {
    right: -39px;
  }

  .slick-prev {
    left: -39px;
  }

  #about-container {
    float: none;
    margin: auto;
    padding-top: 150px;
  }

  #projects .card {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    max-width: 90%;
  }
}

@media (max-width: 400px) {
  #about-container {
    width: 100%;
  }
  #profile-image {
    width: 100%;
  }
}

@media (max-height: 400px) {
  #about-container {
    padding-top: 50px;
  }
}